import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, tap} from 'rxjs';
import {API_URL} from "../../../core/core-urls/api-urls";
import {CreateDialogInput} from "../models/input/create-dialog-input";
import {CreatePersonalDialogInput} from "../models/input/create-personal-dialog-input";
import {CreateGroupDialogInput} from "../models/input/create-group-dialog-input";

/**
 * Класс сервиса модуля коммуникаций.
 * Используется как прокси-сервис для передачи данных между компонентами и как API-сервис.
 */
@Injectable()
export class CommunicationsServiceService {
  public abstractScopes$ = new BehaviorSubject<any>(null);
  public communicationsAbstractGroups$ = new BehaviorSubject<any>(null);
  public groupObjects$ = new BehaviorSubject<any>(null);
  public groupObjectDialogs$ = new BehaviorSubject<any>(null);
  public receiptGroupObjectDialogs$ = new BehaviorSubject<any>(null);
  public dialogMessages$ = new BehaviorSubject<any>(null);
  public receiveDialogMessages$ = new BehaviorSubject<any>(null);
  public createdDialog$ = new BehaviorSubject<any>(null);
  public sendMessage$ = new BehaviorSubject<any>(null);
  public receiveMessage$ = new BehaviorSubject<any>(null);
  public aGroupObjectActions$ = new BehaviorSubject<any>(null);
  public aDialogGroups$ = new BehaviorSubject<any>(null);
  public dialogMembers$ = new BehaviorSubject<any>(null);
  public dialogId$ = new BehaviorSubject<any>(null);
  public createDialog$ = new BehaviorSubject<any>(null);
  public createPersonalDialog$ = new BehaviorSubject<any>(null);
  public personalDialogsData$ = new BehaviorSubject<any>(null);
  public personalDialogs$ = new BehaviorSubject<any>(null);
  public removeDialogMessage$ = new BehaviorSubject<any>(null);
  public removedDialogMessage$ = new BehaviorSubject<any>(null);
  public createdDialogResult$ = new BehaviorSubject<any>(null);
  public createGroupDialog$ = new BehaviorSubject<any>(null);
  public createdGroupDialog$ = new BehaviorSubject<any>(null);
  public createdPersonalDialog$ = new BehaviorSubject<any>(null);

  constructor(private readonly _http: HttpClient) {
  }

  /**
   * Функция отправляет список абстрактных областей подписчикам.
   * @param abstractScopes - Список абстрактных областей.
   */
  public sendAbstractScopes(abstractScopes: any[]) {
    this.abstractScopes$.next(abstractScopes);
  };

  /**
   * Функция отправляет в прокси-сервис выбранную абстрактную область чата.
   * @param abstractScopeId - Id выбранной абстрактной области.
   * @param abstractScopeType - Тип выбранной абстрактной области.
   * @param dialogGroupType - Тип группировки диалогов.
   */
  public sendAbstractScopeGroupObjects(abstractScopeId: number, abstractScopeType: number, dialogGroupType: string) {
    this.communicationsAbstractGroups$.next({abstractScopeId, abstractScopeType, account: localStorage["u_e"], dialogGroupType});
  };

  /**
   * Функция отправляет группы объектов выбранной абстрактной области чата.
   * @param groupsObjects - Список групп объектов.
   */
  public sendGroupObjects(groupsObjects: any) {
    this.groupObjects$.next(groupsObjects);
  };

  /**
   * Функция отправляет в прокси-сервис выбранный объект группы
   * @param abstractScopeId - Id выбранной абстрактной области.
   */
  public sendGroupObject(abstractScopeId: number) {
    this.groupObjectDialogs$.next({abstractScopeId, account: localStorage["u_e"]});
  };

  /**
   * Функция отправляет в прокси-сервис выбранный объект группы
   * @param dialogId - Id диалога.
   */
  public sendDialogMessages(dialogId: number) {
    this.dialogMessages$.next({dialogId, account: localStorage["u_e"]});
  };

  /**
   * Функция отправляет в прокси-сервис сообщения выбранного диалога.
   * @param dialogMessages - Сообщения диалога.
   */
  public receiveDialogMessages(dialogMessages: any[]) {
    this.receiveDialogMessages$.next({dialogMessages});
  };

  /**
   * Функция отправляет в прокси-сервис сообщение диалога.
   * @param dialogMessages - Сообщение диалога.
   */
  public sendMessage(message: any) {
    this.sendMessage$.next(message);
  };

  /**
   * Функция получает из прокси-сервиса сообщение диалога.
   * @param dialogMessages - Сообщение диалога.
   */
  public receiveMessage(message: any) {
    this.receiveMessage$.next(message);
  };

  /**
   * Функция получает элементы меню для групп объектов чата.
   */
  public async getGroupObjectMenuItemsAsync() {
    return await this._http.get(API_URL.apiUrlCommunications + "/communications/menu/group-objects-menu").pipe(
      tap((data: any) => this.aGroupObjectActions$.next(data))
    );
  };

  /**
   * Функция получает элементы меню группировок диалогов чата.
   */
  public async getDialogGroupMenuItemsAsync() {
    return await this._http.get(API_URL.apiUrlCommunications + "/communications/menu/dialog-group-menu").pipe(
      tap((data: any) => this.aDialogGroups$.next(data))
    );
  };

  /**
   * Функция получает участников диалога.
   * @param dialogId - Id диалога.
   */
  public getDialogMembers(dialogId: number) {
    this.dialogId$.next(dialogId);
  };

  /**
   * Функция записывает участников диалога.
   * @param dialogMembers - Участники диалога.
   */
  public receiveDialogMembers(dialogMembers: any[]) {
    this.dialogMembers$.next(dialogMembers);
  };

  /**
   * Функция записывает участников диалога.
   * @param createDialogInput - Входная модель.
   */
  public createDialog(createDialogInput: CreateDialogInput) {
    this.createdDialog$.next(createDialogInput);
  };

  /**
   * Функция создает диалог ЛС и добавляет его участников
   * @param createDialogInput - Входная модель.
   */
  public createPersonalDialog(createDialogInput: CreatePersonalDialogInput) {
    this.createPersonalDialog$.next(createDialogInput);
  };

  /**
   * Функция записывает данные для получения диалогов ЛС.
   */
  public setPersonalDialogsData() {
    this.personalDialogsData$.next({account: localStorage["u_e"]});
  };

  /**
   * Функция получает диалоги ЛС.
   */
  public getPersonalDialogsData(personalDialogs: any[]) {
    this.personalDialogs$.next(personalDialogs);
  };

  /**
   * Функция получает созданный диалог.
   * @param createdDialog - Созданный диалог.
   */
  public getCreatedDialog(createdDialog: any) {
    this.createdDialogResult$.next(createdDialog);
  };

  /**
   * Функция создает групповой диалог и добавляет его участников
   * @param createDialogInput - Входная модель.
   */
  public createGroupDialog(createDialogInput: CreateGroupDialogInput) {
    this.createGroupDialog$.next(createDialogInput);
  };

  /**
   * Функция получает созданный групповой диалог и добавляет его участников
   * @param createdDialog - Созданный групповой.
   */
  public getCreatedGroupDialog(createdDialog: any) {
    this.createdGroupDialog$.next(createdDialog);
  };

  /**
   * Функция получает созданный диалог ЛС.
   */
  public getCreatedPersonalDialog(pcreatedPrsonalDialog: any) {
    this.createdPersonalDialog$.next(pcreatedPrsonalDialog);
  };

  /**
   * Функция фиксирует удаляемое сообщение.
   */
  removeDialogMessage(dialogId: number, messageId: number) {
    this.removeDialogMessage$.next({dialogId, messageId});
  };

  /**
   * Функция фиксирует удаленное сообщение.
   */
  removedDialogMessage(messageId: number) {
    this.removedDialogMessage$.next({messageId});
  };
}
