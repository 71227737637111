<div class="d-flex block-profile" [ngClass]="{'mt5': isShowInviteBlock}">
  <div class="profile-left-menu">
<!--    <p-panelMenu [model]="menuItems" (click)="onSelectMenu($event)">-->
<!--    </p-panelMenu>-->

    <p-tieredMenu [model]="profileItems$.value.items" />
  </div>

  <div class="">

  </div>

  <!-- Диалог необходимости создания компании. -->
  <p-dialog header="Создайте вашу компанию"
            [(visible)]="isCreateCompany"
            [breakpoints]="{'960px': '75vw'}"
            [style]="{width: '50vw'}"
            [draggable]="false"
            [resizable]="false">
    <p>У вас не создано ни одной компании. Для создания проекта, сначала нужно создать компанию.</p>

    <div class="mt-3">
      <div>
        <strong>Введите название компании</strong>
      </div>

      <div>
        <input type="text"
               class="p-input-text-sm input-control-worksheet"
               placeholder="Введите название компании"
               pInputText
               autocomplete="new-password"
               [(ngModel)]="companyName"
               [style]="{width: '300px'}"/>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button (click)="isCreateCompany=false"
                label="Отменить"
                styleClass="p-button-text p-button-danger">

      </p-button>

      <p-button label="Создать компанию и перейти к созданию проекта"
                styleClass="p-button-text p-button-success"
                (click)="onCreateCompanyAsync()"
                [disabled]="!companyName">
      </p-button>
    </ng-template>
  </p-dialog>

  <!-- Диалог необходимости выбора компании. -->
  <p-dialog header="Выберите компанию"
            [(visible)]="isSelectCompany"
            [breakpoints]="{'960px': '75vw'}"
            [style]="{width: '50vw', height: '50vh'}"
            [draggable]="false"
            [resizable]="false">
    <p>У вас создано более одной компании. Для создания проекта, пожалуйста, выберите к какой компании будет относиться новый проект.</p>

    <div class="d-flex mt-3">
      <div class="control-input-text-worksheet">
        <div>
          <label class="create-project-required-field">Выберите компанию</label>
        </div>
        <div>
          <p-dropdown [options]="aUserCompanies"
                      [(ngModel)]="selectedCompany"
                      optionLabel="companyName"
                      appendTo="body"
                      class="control-project-stages">
          </p-dropdown>
        </div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button (click)="isSelectCompany=false"
                label="Отменить"
                styleClass="p-button-text p-button-danger">

      </p-button>

      <p-button label="К созданию проекта"
                styleClass="p-button-text p-button-success"
                [disabled]="!selectedCompany"
                (click)="onRouteCreateProject()">
      </p-button>
    </ng-template>
  </p-dialog>
</div>
