<p-toast></p-toast>

<div class="mb-1 telegram-invite d-flex justify-content-center col-lg-12">
  <div class="invite-telegram">
    <div class="block-invite" *ngIf="isShowInviteBlock">
      <div *ngIf="!isVisibleNotifyChat">
        <span>Будьте всегда в курсе новых событий на платформе вступив в наш уютный Telegram-канал.</span>&nbsp;
      </div>

      <div *ngIf="isVisibleNotifyChat">
        <span>Будьте всегда в курсе новых проектов и вакансий на платформе вступив в наш уютный Telegram-канал.</span>&nbsp;
      </div>

      <div class="btn-invite-telegram">
        <p-button label="Присоединиться" styleClass="p-button-link" (click)="onCreateInviteLinkTelegramAsync()"></p-button>
      </div>
    </div>
  </div>
</div>

<div class="d-flex block-profile">
  <div class="profile-left-menu col-lg-3">

  </div>

  <div class="block-worksheet col-lg-7">
    <!-- <div *ngIf="isShowRemarks" class="mb-1">
      <strong>Имеются замечания. Анкета не будет опубликована, пока замечания не будут исправлены и приняты модератором.</strong>
    </div> -->
    <!-- TODO: Добавлено пока не починим функционал замечаний. -->
    <div *ngIf="isEmptyProfile" class="mb-1 worksheet-panel">
      <div class="mb-1">
        <div>
          <p-messages [(value)]="messages" [closable]="false"/>
        </div>
      </div>
    </div>

    <!-- <div class="mb-1" *ngFor="let r of profileInfo$.value.resumeRemarks">
      <div>
        <p-message severity="warn" text="{{r.remarkText}}"></p-message>
      </div>
    </div> -->


    <div class="worksheet-panel">
      <p-panel header="Контактная информация" [toggleable]="true">
        <div class="block-contact">
          <div class="control-input-text-worksheet" >
            <label class="d-flex flex-column">
              <strong [ngClass]="{'required':isModeEdit}">Фамилия</strong>
              <input type="text"
                     class="p-inputtext-sm input-control-worksheet "
                     placeholder="Фамилия"
                     pInputText
                     autocomplete="new-password"
                     [(ngModel)]="lastName"
                     *ngIf="isModeEdit" />
              <span *ngIf="isModeView">{{profileInfo$.value.lastName}}</span>
            </label>

                <!-- <div class="field-checkbox">
                    <p-checkbox [(ngModel)]="isShortFirstName"
                                [binary]="true"
                                inputId="short-first-name"
                                *ngIf="isModeEdit">
                  </p-checkbox>
                    <p-checkbox [(ngModel)]="isShortFirstName"
                                [binary]="true"
                                inputId="short-first-name"
                                *ngIf="isModeView"
                                [disabled]="true">
                  </p-checkbox>
                    <label for="short-first-name">
                        <small>Отображать только первую букву фамилии</small>
                    </label>
                </div> -->
          </div>

          <div class="control-input-text-worksheet">
            <label class="d-flex flex-column">
              <strong [ngClass]="{'required':isModeEdit}">Имя</strong>
              <input type="text"
                     class="p-inputtext-sm input-control-worksheet"
                     placeholder="Имя"
                     pInputText
                     autocomplete="new-password"
                     [(ngModel)]="firstName"
                     *ngIf="isModeEdit" />
              <span *ngIf="isModeView">{{profileInfo$.value.firstName}}</span>
            </label>
          </div>

          <div class="control-input-text-worksheet ">
            <label class="d-flex flex-column">
              <strong>Отчество</strong>
              <input type="text"
                     class="p-inputtext-sm input-control-worksheet"
                     placeholder="Отчество"
                     pInputText
                     autocomplete="new-password"
                     [(ngModel)]="patronymic"
                     *ngIf="isModeEdit" />
              <span *ngIf="isModeView">{{profileInfo$.value.patronymic}}</span>
            </label>
          </div>
        </div>

        <div class="d-flex worksheet-line justify-content-between">
          <div class="control-input-text-worksheet">
            <label class="d-flex flex-column">
              <strong>Email</strong>
              <input type="text"
                     class="p-inputtext-sm input-control-worksheet"
                     placeholder="Email"
                     pInputText
                     autocomplete="new-password"
                     [(ngModel)]="email"
                     *ngIf="isModeEdit" />
              <span *ngIf="isModeView">{{profileInfo$.value.email}}</span>
              <small *ngIf="!isModeView">При изменении Email, Вам нужно будет авторизоваться повторно. Вы&nbsp;будете перенаправлены автоматически.</small>
            </label>
          </div>

          <div class="control-input-text-worksheet">
            <label class="d-flex flex-column">
              <strong [ngClass]="{'required':isModeEdit}">Номер телефона</strong>
              <div class="phone-input-container" *ngIf="isModeEdit">
                <p-inputMask
                  mask="(999) 999-99-99"
                  class="p-inputtext-sm input-control-worksheet input-phone"
                  placeholder="(999) 999-99-99"
                  autocomplete="new-password"
                  [(ngModel)]="phoneNumber"
                  unmask="true"
                />
                <span class="phone-start">+7</span>
              </div>
              <span *ngIf="isModeView">
                {{
                  (profileInfo$.value.phoneNumber == null || profileInfo$.value.phoneNumber == '')
                  ? 'Не указан'
                  : '+7 ' + (profileInfo$.value.phoneNumber | phoneFormat )
                }}
              </span>
            </label>
          </div>
        </div>

        <div class="d-flex worksheet-line justify-content-between">
          <div class="control-input-text-worksheet">
            <label class="d-flex flex-column">
              <strong>Telegram</strong>
              <input type="text"
                     class="p-inputtext-sm input-control-worksheet"
                     placeholder="Telegram"
                     pInputText
                     autocomplete="new-password"
                     [(ngModel)]="telegram"
                     *ngIf="isModeEdit" />
              <span *ngIf="isModeView">
                {{
                  (profileInfo$.value.telegram == '' || profileInfo$.value.telegram == null)
                  ? 'Не указан'
                  : profileInfo$.value.telegram
                }}
              </span>
            </label>
          </div>

          <div class="control-input-text-worksheet">
            <label class="d-flex flex-column">
              <strong>WhatsApp</strong>
              <input type="text"
                      class="p-inputtext-sm input-control-worksheet"
                      placeholder="WhatsApp"
                      pInputText
                      autocomplete="new-password"
                      [(ngModel)]="whatsApp"
                      *ngIf="isModeEdit" />
              <span *ngIf="isModeView">
                {{
                  (profileInfo$.value.whatsApp == '' || profileInfo$.value.whatsApp == null)
                  ? 'Не указан'
                  : profileInfo$.value.whatsApp
                }}
              </span>
            </label>
          </div>

          <div class="control-input-text-worksheet">
            <label class="d-flex flex-column">
              <strong>Ссылка ВКонтакте</strong>
              <input type="text"
                     class="p-inputtext-sm input-control-worksheet"
                     placeholder="ВКонтакте"
                     pInputText
                     autocomplete="new-password"
                     [(ngModel)]="vkontakte"
                     *ngIf="isModeEdit" />
              <span *ngIf="isModeView">
                {{
                  (profileInfo$.value.vkontakte == '' || profileInfo$.value.vkontakte == null)
                  ? 'Не указана'
                  : profileInfo$.value.vkontakte
                }}
              </span>
            </label>
          </div>
        </div>

        <div class="d-flex worksheet-line justify-content-between">
          <div class="control-input-text-worksheet">
            <label class="d-flex flex-column">
              <strong>Другая ссылка</strong>
              <input type="text"
                      class="p-inputtext-sm input-control-worksheet"
                      placeholder="Другая соц.сеть"
                      pInputText
                      autocomplete="new-password"
                      [(ngModel)]="otherLink"
                      *ngIf="isModeEdit" />
              <span *ngIf="isModeView">
                {{
                  (profileInfo$.value.otherLink == '' || profileInfo$.value.otherLink == null)
                  ? 'Не указана'
                  : profileInfo$.value.otherLink
                }}
              </span>
            </label>
          </div>
        </div>
<!--
        <div class="d-flex worksheet-line">
            <div class="control-input-text-worksheet">
                <button type="submit"
                      class="btn btn-primary button-action-pur-control-worksheet">
                Сохранить
              </button>
            </div>
        </div> -->
      </p-panel>
    </div>

    <div class="worksheet-panel space-top">
      <p-panel header="О себе" [toggleable]="true">
        <form class="d-flex worksheet-line" [formGroup]="formAboutme">
          <div
            class="control-input-text-worksheet"
            [ngClass]="{'required':isModeEdit}"
          >
          <p-editor
            formControlName="aboutme"
            placeholder="Напишите о себе"
            [readonly]="isModeView"
            class="input-about-me"
            [ngClass]="{'headless-editor frameless' : isModeView}"
          />
          </div>
        </form>

        <div class="d-flex worksheet-line">
            <div [ngClass]="{'required':isModeEdit}"
                  class="control-input-text-worksheet">
                <input type="text"
                        class="p-inputtext-sm input-control-worksheet"
                        placeholder="Должность"
                        pInputText
                        autocomplete="new-password"
                        [(ngModel)]="job"
                        *ngIf="isModeEdit" />
                <div *ngIf="isModeView">
                  <label>
                    <strong>Должность</strong>
                  </label>
                </div>
                <span *ngIf="isModeView">{{profileInfo$.value.job}}</span>
            </div>

            <!-- TODO: Передавать это поле на бэк, когда там будет оно добавлено. -->
            <div [ngClass]="{'required-number':isModeEdit}"
                  class="control-input-text-worksheet">
                <p-inputNumber
                    *ngIf="isModeEdit"
                    [(ngModel)]="workExperience"
                    class="p-inputtext-sm"
                    placeholder="Опыт (стаж)"
                    mode="decimal"
                    [min]="0"
                    [max]="100"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="1"
                    [useGrouping]="false"
                    [showButtons]="true"
                />

                <div *ngIf="isModeView">
                  <label>
                    <strong>Опыт (стаж)</strong>
                  </label>
                </div>
                <span *ngIf="isModeView">{{profileInfo$.value.workExperience}}</span>
            </div>
        </div>

        <!-- <div class="d-flex worksheet-line">
            <div class="control-input-text-worksheet">
                <button type="submit"
                      class="btn btn-primary button-action-pur-control-worksheet">
                Сохранить
              </button>
            </div>
        </div> -->
      </p-panel>
    </div>

    <div class="worksheet-panel space-top">
      <div [ngClass]="{'required-panel':isModeEdit}"
            class="p-panel-el">
        <p-panel header="Навыки" [toggleable]="true" >
          <div *ngIf="isModeEdit; else elseBlockSkills">
          <p-pickList [source]="profileSkillsItems$.value"
                      [target]="aSelectedSkills"
                      sourceHeader="Выберите навыки"
                      targetHeader="Выбранные навыки"
                      [dragdrop]="true"
                      [responsive]="true"
                      [sourceStyle]="{'height':'30rem'}"
                      [targetStyle]="{'height':'30rem'}"
                      filterBy="skillName"
                      sourceFilterPlaceholder="Найти по названию"
                      targetFilterPlaceholder="Найти по названию"
                      (onMoveToTarget)="onSaveProfileUserSkillsAsync()">
      <ng-template  let-product pTemplate="item" >
          <div class="product-item">
              <div class="image-container">
                      <!-- <img src="assets/showcase/images/demo/product/{{product.image}}" [alt]="product.name" class="product-image" /> -->
              </div>
              <div class="product-list-detail">
                  <h5 class="mb-2">{{product.skillName}}</h5>
                  <i class="pi pi-tag product-category-icon"></i>
                  <span class="product-category">{{product.tag}}</span>
              </div>
              <div class="product-list-action">
                  <!-- <h6 class="mb-2">${{product.price}}</h6> -->
                  <!-- <span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span> -->
              </div>
          </div>
      </ng-template>
          </p-pickList>
          </div>
            <ng-template #elseBlockSkills>
              <div *ngFor="let ski of aSelectedSkills">
                {{ski.skillName}}
              </div>
            </ng-template>
        </p-panel>
      </div>

      <p *ngIf="isModeEdit">
        Не нашли Ваш навык? Предложите его через форму пожеланий и предложений на главной странице платформы!
        <br>Возможно, мы включим его в список навыков на платформе.
      </p>
    </div>


    <div class="worksheet-panel space-top">
      <div [ngClass]="{'required-panel':isModeEdit}"
            class="p-panel-el">
        <p-panel header="Цели на платформе" [toggleable]="true" >
          <div *ngIf="isModeEdit; else elseBlockIntents">
          <p-pickList [source]="profileIntentsItems$.value"
                    [target]="aSelectedIntents"
                    sourceHeader="Выберите ваши цели на платформе"
                    targetHeader="Выбранные цели"
                    [dragdrop]="true"
                    [responsive]="true"
                    [sourceStyle]="{'height':'30rem'}"
                    [targetStyle]="{'height':'30rem'}"
                    filterBy="name"
                    sourceFilterPlaceholder="Найти по названию"
                    targetFilterPlaceholder="Найти по названию">
    <ng-template let-product pTemplate="item">
        <div class="product-item">
            <div class="image-container">
                    <!-- <img src="assets/showcase/images/demo/product/{{product.image}}" [alt]="product.name" class="product-image" /> -->
            </div>
            <div class="product-list-detail">
                <h5 class="mb-2">{{product.intentName}}</h5>
                <!-- <i class="pi pi-tag product-category-icon"></i>
                <span class="product-category">{{product.tag}}</span> -->
            </div>
            <div class="product-list-action">
                <!-- <h6 class="mb-2">${{product.price}}</h6> -->
                <!-- <span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span> -->
            </div>
        </div>
    </ng-template>
</p-pickList>
          </div>
          <ng-template #elseBlockIntents>
            <div *ngFor="let int of aSelectedIntents">
              {{int.intentName}}
            </div>
          </ng-template>
        </p-panel>
      </div>

      </div>

      @if (isModeEdit) {
        <div class="d-flex worksheet-line worksheet-panel mb-3">
          <div class="control-input-text-worksheet">
            <button
              type="button"
              class="btn btn-primary button-action-pur-control-worksheet"
              (click)="onSaveProfileInfoAsync()"
            >
              Сохранить
            </button>
          </div>
        </div>
      }
  </div>
</div>
