<p-toast></p-toast>

<!--<div class="left-panel" (mouseover)="onSelectPanelMenu()">-->
<!--  <div style="border-left:1px solid #C0C6CF;height:1500px;width: 50px;position: absolute;"></div>-->
<!--</div>-->

<div class="d-flex">
  <div class="col-lg-2">
    <left-panel></left-panel>
  </div>

  <div class="col-lg-10">
    <div class="block-actions d-flex" *ngIf="isShowAvailableActions">
      <div class="d-flex actions-line">
        <div>
          <button pButton
                  pRipple
                  icon="pi pi-play"
                  type="button"
                  label="Начать спринт"
                  class="p-button-success p-button-text p-button-sm"
                  (click)="onRunSprintAsync()">
          </button>
        </div>

        <div>
          <button pButton
                  pRipple
                  icon="pi pi-stop"
                  type="button"
                  label="Завершить спринт"
                  class="p-button-secondary p-button-text p-button-sm"
                  (click)="onManualCompleteSprintAsync(false)">
          </button>
        </div>

        <!-- TODO: Пока скрыл, так как не реализовали логику закрытия на бэке еще.  -->
        <!--   <div>-->
        <!--     <button pButton-->
        <!--             pRipple-->
        <!--             icon="pi pi-time"-->
        <!--             type="button"-->
        <!--             label="Закрыть спринт"-->
        <!--             class="p-button-danger p-button-text p-button-sm">-->
        <!--     </button>-->
        <!--   </div>-->
      </div>
    </div>

    <div class="d-flex block-profile">
      <!--  <div class="profile-left-menu col-lg-3 col-xl-3 col-xxl-3">-->

      <!--  </div>-->
      <div class="worksheet-panel block-worksheet sprints-table">
        <h3>Спринты проекта</h3>
        <p-tabView>
          <p-tabPanel header="Активные">
            <ng-container
              *ngTemplateOutlet="sprintList; context:{$implicit: sprints$.value.sprintsInWork}"
            ></ng-container>
          </p-tabPanel>
          <p-tabPanel header="Новые">
            <ng-container
              *ngTemplateOutlet="sprintList; context:{$implicit: sprints$.value.sprintsNew}"
            ></ng-container>
          </p-tabPanel>
          <p-tabPanel header="Завершенные">
            <ng-container
              *ngTemplateOutlet="sprintList; context:{$implicit: sprints$.value.sprintsCompleted}"
            ></ng-container>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>

    <!-- Темплейт списка спринтов -->
    <ng-template #sprintList let-sprints>
      <p-table [value]="sprints"
               [(selection)]="selectedSprint"
               selectionMode="single"
               [paginator]="sprints.length > 10"
               [rows]="10"
               styleClass="p-datatable-gridlines"
               responsiveLayout="scroll"
      >

        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th>ID</th>
            <th>Название</th>
            <th>Дата начала</th>
            <th>Дата окончания</th>
            <th>Статус</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-sp>
          <tr>
            <td>
              <p-tableCheckbox [value]="sp" (click)="onSelectRowSprint(sp.projectSprintId)"></p-tableCheckbox>
            </td>
            <td class="table-data">
              <div class="btn btn-link" (click)="onSelectSprint($event, sp.projectSprintId)">
                {{sp.projectSprintId}}
              </div>
            </td>
            <td class="table-data">
              <div class="btn btn-link" (click)="onSelectSprint($event, sp.projectSprintId)">
                {{sp.sprintName}}
              </div>
            </td>
            <td class="">{{sp.dateStart ?? 'Не задана'}}</td>
            <td class="">{{sp.dateEnd ?? 'Не задана'}}</td>
            <td class="">{{sp.sprintStatusName}}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="summary">
          Всего: {{sprints.length}}
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">
              <div class="empty-projects">
                Вы не спланировали еще ни одного спринта.
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
    <!-- Конец темплейта списка спринтов -->
  </div>
</div>

<!-- Модалка переноса нерешенных задач спринта. -->
<p-dialog header="Куда перенести нерешенные задачи спринта?"
          [(visible)]="isShowAvailableSprints"
          [breakpoints]="{'960px': '75vw'}"
          [style]="{width: '50vw', height: '50vw'}"
          [draggable]="false"
          [resizable]="false">
  <p-dropdown
    [options]="aVariants"
    [(ngModel)]="selectedVariant"
    optionLabel="variantName"
    placeholder="Выберите куда перенести"
    (click)="onSelectVariantAsync()">
  </p-dropdown>

  <div class="mt-3" *ngIf="aAvailableSprints.length > 0 && isNextSprint">
    <div>
      <strong>Выберите название спринта</strong>
    </div>

    <div>
      <p-listbox
        [options]="aAvailableSprints"
        [(ngModel)]="selectedAvailableSprint"
        optionLabel="sprintName"
        [style]="{'width':'15rem'}"
        [listStyle]="{'max-height': '220px'}">
      </p-listbox>
    </div>
  </div>

  <div class="mt-3">
    <input type="text"
           class="p-inputtext-sm input-control-worksheet "
           placeholder="Введите название нового спринта"
           pInputText
           autocomplete="new-password"
           [(ngModel)]="moveSprintName"
           *ngIf="isNewSprint" />
  </div>


  <ng-template pTemplate="footer">
    <button pButton type="button"
            label="Закрыть"
            class="p-button-outlined p-button-secondary"
            (click)="isShowAvailableSprints = false">
    </button>

    <button pButton type="button"
            label="Переместить и завершить"
            class="p-button-outlined p-button-success"
            (click)="onManualCompleteSprintAsync(true)">
    </button>
  </ng-template>
</p-dialog>

<!--<left-panel-->
<!--  (mouseleave)="onClosePanelMenu()"-->
<!--&gt;</left-panel>-->
